import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppURL } from 'app/classes/app-url';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountCouponService {

  constructor(private http: HttpClient) { }

  getDiscountCoupon(pageNumber : any) : Observable<any> {
    pageNumber=pageNumber - 1;
    let params = new HttpParams().set('pageNumber', pageNumber.toString()).set('size', '50');
   return this.http.get<any>(AppURL.discountCoupon,{ params: params });
  }
}
