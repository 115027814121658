import { Component, OnInit } from '@angular/core';
import { User } from 'app/classes/user';
import { CountryCodeService } from 'app/shared/util/country-code.service';
import { CamrillaUsersService } from './camrilla-users.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder, FormControl } 
    from '@angular/forms';
import { CustomvalidationService } from 'app/shared/util/customvalidation-service.service';
import { ResetPassword } from 'app/classes/reset-password';
import { NotificationService } from 'app/shared/util/notification.service';
import { KeyValue } from 'app/classes/KeyValue';

@Component({
  selector: 'app-camrilla-users',
  templateUrl: './camrilla-users.component.html'
})
export class CamrillaUsersComponent implements OnInit {
  pageNumber : number = 1 ;
  userList : User[];
  closeResult: string;
  searchResult : boolean=false;
  planSearch : boolean=true;
  planSearchValue : string="";
  keySearch : boolean =true;
  userSearchForm : FormGroup;
  resetPasswordform : FormGroup;
  resetPassword :  ResetPassword ;

  searchKeyValue : KeyValue = new KeyValue();

  constructor(private camrillaUsersService : CamrillaUsersService, 
    private countryCodeService : CountryCodeService,
    private modalService : NgbModal,
    private fb: FormBuilder,
    private customValidator: CustomvalidationService,
    private notificationService : NotificationService) { }

  ngOnInit(): void {
    this.resetPassword = new ResetPassword();
    this.getUserList(this.pageNumber,null);
    this.resetPasswordform = this.fb.group({
      password: ['', Validators.compose([Validators.required,Validators.minLength(6)])],
      confirmPassword: ['', Validators.compose([Validators.required,Validators.minLength(6)])],
    },
      {
        validator: this.customValidator.MatchPassword('password', 'confirmPassword'),
      }
    );
    this.userSearchForm=this.fb.group({
      searchValue: new FormControl({value: '', disabled: !this.keySearch}),
      searchKey:new FormControl({value: '', disabled: !this.keySearch}),
    });
  }

  getUserList(pageNumber : any, searchData : any){
    this.camrillaUsersService.getUsers(pageNumber,searchData).subscribe((response : any)=>{
      this.userList=response.data;
  });
  }

  getNextPage(){
    this.pageNumber++;
    if(this.keySearch && this.searchKeyValue.key != ""){
      this.getUserList(this.pageNumber,this.userSearchForm.value);
    }else if(this.planSearch){
      this.getUsersByPlan();
    }
    
  }
  getPreivousPage(){
    if(this.pageNumber>1){
      let nextPage= this.pageNumber - 1 ;
      this.pageNumber=nextPage;
      if(this.keySearch && this.searchKeyValue.key != ""){
        this.getUserList(1,this.userSearchForm.value);
      }else if(this.planSearch){
        this.getUsersByPlan();
      }
     
   }  
  }

  getUsersByPlan =() =>{
    this.camrillaUsersService.getUsersByPlan(this.pageNumber,this.planSearchValue).subscribe((response : any)=>{
      this.userList=response.data;
    });
  }

  searchUser(){
      if(this.keySearch && this.searchKeyValue.key != ""){
        this.getUserList(1,this.userSearchForm.value);
      }else if(this.planSearch){
        this.getUsersByPlan();
      }
  }

  onSearchPlanChange(event){
    if(event.target.value=="Select"){
      this.planSearch=true;
      this.keySearch=true;
    }else{
    this.planSearchValue=event.target.value;
    this.userSearchForm.get("searchValue").value;
    this.planSearch=true;
    this.keySearch=false;
    this.userSearchForm.reset();
    }

  }

  restSearch =() =>{
    console.log("search reset");
  }

  onSearchKeyChange(event){
    if(event.target.value=="Select"){
      this.planSearch=true;
      this.keySearch=true;
    }else{
    console.log(event.target.value)
    this.planSearch=false;
    this.keySearch=true;
    
  }
  }

  onChangePageNumber(event: any){
    let pageNumber =event.target.value
    console.log(pageNumber);
    if(pageNumber > 1 ){
      this.getUserList(pageNumber,null)
      this.pageNumber=pageNumber
    }else{
      this.pageNumber= 1
      this.getUserList(this.pageNumber,null)
    }

  }

  resetPasswordModal(target,email) {
    this.resetPassword.email=email;
    console.log(email);
    this.modalService.open(target, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onSubmit() {
    this.resetPassword.newPassword=this.resetPasswordform.value.password;
    console.log(this.resetPassword);
    this.modalService.dismissAll();
    this.camrillaUsersService.reestUserPassword(this.resetPassword).subscribe((response : any)=>{
      if(response.code==0){
        this.notificationService.success("Password Reset Success");
      }else{
        this.notificationService.error("Password Reset Failed");
      }
      });
    }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
