import { Injectable } from '@angular/core';
import decode from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(private router : Router) { }

  saveUserDataIfAdmin(data : any){
    if(decode(data.token.accessToken).authorities[0].authority==='ADMIN'){
      localStorage.setItem('user', JSON.stringify(data));
      return true;
    }
    return false;
  }
  
  isValidToken(){
    let user = decode(JSON.parse(localStorage.getItem('user')).token.accessToken);
    if (this.getNowUTC() >= (user.exp * 1000)) {
      console.log("InValid Token");
      this.removeUserData();
      this.router.navigate(['login']);
      return false;
    }
    console.log("Valid Token");
    return true;
    
  }
  private getNowUTC() {
    let now = new Date;
    let utcTimestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() , 
      now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
      return utcTimestamp;
  }

  getUserToken(){
    if(localStorage.getItem('user')!=null){
      return JSON.parse(localStorage.getItem('user')).token.accessToken;
    }
    return null;
  }

  getUserRefreshToken(){
    if(localStorage.getItem('user')!=null){
      return JSON.parse(localStorage.getItem('user')).token.refreshToken;
    }
    return null;
  }

  removeUserData(){
    localStorage.removeItem('user');
  }

  checkUserTokenValid(){
    if(JSON.parse(localStorage.getItem('user')) != null && this.isValidToken()){
      return true;
    }
    return false;
  }
}
