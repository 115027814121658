import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import {ReactiveFormsModule} from "@angular/forms";

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CanActivateRouteGuard } from './shared/auth-service/can-activate-route-guard.service';
import { CamrillaUsersComponent } from './pages/camrilla-users/camrilla-users.component';
import { TokenInterceptor } from './shared/auth-service/token.interceptor';
import { LoaderService } from './shared/loader/loader.service';
import { DiscountCouponComponent } from './pages/discount-coupon/discount-coupon.component';
import { UpgradeUserComponent } from './pages/upgrade-user/upgrade-user.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    CamrillaUsersComponent,
    DiscountCouponComponent,
    UpgradeUserComponent
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes,{
    useHash: true,
    relativeLinkResolution: 'legacy'
}),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    FormsModule,
    HttpClientModule,
    NgbModule
  ],
  providers: [CanActivateRouteGuard,
    { provide: HTTP_INTERCEPTORS, useClass:TokenInterceptor,multi: true } ,
    LoaderService 
  ],
  
  bootstrap: [AppComponent]
})

export class AppModule { }
