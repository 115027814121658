export class AppURL {
public static baseURL : string = 'http://api.camrilla.com'
//public static baseURL : string = 'http://apitest.camrilla.com'
//public static baseURL : string = 'http://localhost:8080'
public static login : string = AppURL.baseURL+'/user/login'
public static refreshToken : string = AppURL.baseURL+'/user/update-access-token'

public static userList : string = AppURL.baseURL+'/admin/user'
public static userListByPlan : string = AppURL.baseURL+'/admin/user/plan/'
public static resetPassword : string = AppURL.baseURL+'/admin/reset-password'

public static dashboard : string = AppURL.baseURL+'/admin/dashboard'
public static discountCoupon : string = AppURL.baseURL+'/admin/discount-coupon'

}
