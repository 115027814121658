import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { UserDataService } from '../user-data/user-data.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
    constructor(private userDataService : UserDataService) {}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.userDataService.getUserToken()!=null){
        if(!this.userDataService.isValidToken()){
            
        }
        request = request.clone({
        setHeaders: {
            Authorization: `Bearer ${this.userDataService.getUserToken()}`
        }
        });
    }
    return next.handle(request);
  }
}