import { Component, OnInit } from '@angular/core';
import { CountryCodeService } from 'app/shared/util/country-code.service';
import { DiscountCouponService } from './discount-coupon.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-discount-coupon',
  templateUrl: './discount-coupon.component.html',
  styleUrls: [  './discount-coupon.component.css']
})
export class DiscountCouponComponent implements OnInit {
  closeResult: string;
  pageNumber : number = 1 
  discountCouponList : any[]
  constructor(private discountCouponService : DiscountCouponService,
    private countryCodeService : CountryCodeService,private modalService: NgbModal) { }

  ngOnInit(): void {
      this.getDiscountCouponList(1)
  }

  getDiscountCouponList(pageNumber : any){
    this.discountCouponService.getDiscountCoupon(pageNumber).subscribe((response : any)=>{
      console.log(response.data)
      this.discountCouponList=response.data;
  });
  }

  add(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onChangePageNumber(event: any){
    let pageNumber =event.target.value
    console.log(pageNumber);
  }
  getNextPage(){
 
  }
  getPreivousPage(){
 
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
