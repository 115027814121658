import { Component, OnInit } from '@angular/core';
import { User } from 'app/classes/user';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { UserDataService } from 'app/shared/user-data/user-data.service';
import { NotificationService } from 'app/shared/util/notification.service';
import { UserLogin } from 'app/classes/user-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user = new UserLogin();
  
  constructor(private loginService:LoginService,private router : Router,
    private userDataService : UserDataService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onLogin(){
      console.log(this.user);
      this.loginService.userLogin(this.user).subscribe(response => {
        console.log(response)
        if(response.code==0 && this.userDataService.saveUserDataIfAdmin(response.data)){
          this.router.navigate(['dashboard']);
          this.notificationService.success("Welcome to Camrilla Admin");
        }else if(response.code==403){
          this.notificationService.error("Only Admin Can Login");
        }else{
        this.notificationService.error("Invalid Credential");
      }
      })

  }

}
