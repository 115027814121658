import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppURL } from 'app/classes/app-url';
import { KeyValue } from 'app/classes/KeyValue';
import { ResetPassword } from 'app/classes/reset-password';
import { User } from 'app/classes/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CamrillaUsersService {
  
  constructor(private http: HttpClient) { }

  getUsers(pageNumber : any, searchData : any) : Observable<User[]> {
    pageNumber=pageNumber - 1;
    let params = null;
    if(searchData!=null){
      params = new HttpParams().set('pageNumber', pageNumber.toString()).set('size', '50')
      .set(searchData.searchKey,searchData.searchValue);
    }else{
      params = new HttpParams().set('pageNumber', pageNumber.toString()).set('size', '50');
    }
    
    
   return this.http.get<User[]>(AppURL.userList,{ params: params });
  }

  getUsersByPlan(pageNumber: number, planSearchValue: string) : Observable<User[]> {
    pageNumber=pageNumber - 1;
    let params = new HttpParams().set('pageNumber', pageNumber.toString()).set('size', '50');
    return this.http.get<User[]>(AppURL.userListByPlan+planSearchValue,{ params: params });
  }

  reestUserPassword(resetPassword : ResetPassword) : Observable<User[]> {
    return this.http.put<User[]>(AppURL.resetPassword,resetPassword);
  }
}
