import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountryCode } from 'app/classes/country-code';

@Injectable({
  providedIn: 'root'
})
export class CountryCodeService {
  countryCodeList : CountryCode[]
  constructor(private httpClient: HttpClient) { 
    this.httpClient.get("assets/iso-country-code.json").subscribe((countryCodeList : CountryCode[]) =>{
      this.countryCodeList=countryCodeList;
    })
  }

  getCountryName(code : string){
       let result =this.countryCodeList.filter( c =>{
          return c.Code===code
      })
      return result[0].Name
      
  }
}
