import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppURL } from 'app/classes/app-url';
import { User } from 'app/classes/user';
import { UserLogin } from 'app/classes/user-login';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  constructor(private http: HttpClient) { }

  userLogin(user : UserLogin) : Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(user);
    console.log(body);
   return this.http.post<any>(AppURL.login,body,{'headers':headers})
  }
}
